import React from "react";
import { useTranslation } from "react-i18next"

const Contact = () => {

    const { t, i18n } = useTranslation('Contact');

    return <div>
        <div style={{textAlign: "center"}}>
            kompetansesenter<br />
            ul. Szczytnicka 30/3<br />
            50-383 Wrocław,<br />
            {t("country")}<br />
            <br />
            <div className="inline icon fa-phone"></div> +48 509 64 54 46 {t("hours")}<br />
            <div className="inline icon fa-envelope"></div> kompetansesenter@gmail.com<br />

        </div>
        <ul className="icons">
            <li>
                <a href="https://www.facebook.com/bolanowskipl-221494364903168/" className="icon fa-facebook">
                    <span className="label">Facebook</span>
                </a>
            </li>
            <li>
                <a href="https://www.instagram.com/kompetansesenter/" className="icon fa-instagram">
                    <span className="label">Instagram</span>
                </a>
            </li>
        </ul>
    </div>
}

export default Contact