import React from 'react'
import { useTranslation } from "react-i18next"
function B2() {
	const { t, i18n } = useTranslation('B2');
		return (<div>
		<h3>{t("naglowek")}</h3>
		<ul>
			<li>
				<h4>{t("kurs1.naglowek")}</h4>
				<p>
				{t("kurs1.termin")}      <br />
				<strong>{t("charakter")}</strong>{t("kurs1.charakter")}<br />
				<strong>{t("status")}</strong>{t("kurs1.status")}      <br />
				</p>
			</li>
			<li>
				<h4>{t("kurs2.naglowek")}</h4>
				<p>
				{t("kurs2.termin")}      <br />
				<strong>{t("charakter")}</strong>{t("kurs2.charakter")}<br />
				<strong>{t("status")}</strong>{t("kurs2.status")}      <br />
				</p>
			</li>
		</ul>
		</div>)
}

export default B2
