import React from "react";
import { useTranslation } from "react-i18next"

const Teacher = () => {
	const { t, i18n } = useTranslation('Teacher');

    return <div>
        <h2 className="major">Wojciech Bolanowski</h2>
        <p>{t('p1')}</p>
        <p>{t('p2')}</p>
        <p>{t('p3')}</p>
        <p>{t('p4')}</p>
        <p>{t('p5')}</p>
        <p>{t('p6')}</p>
	<p>{t('p7')}</p>
	<p>{t('p8')}</p>
	<p>{t('p9')}</p>
	<p>{t('p10')}</p>
	<p>{t('p11')}</p>
    </div>
}

export default Teacher