import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'

import A1 from './courses/A1'
import A2 from './courses/A2'
import B1 from './courses/B1'
import B2 from './courses/B2'


import { useTranslation } from "react-i18next"

import Student from './students/Student'
import StudentCarousel from './students/StudentCarousel'
import Teacher from './Teacher'
import Contact from './Contact'

function Main(props) {
  const { t, i18n } = useTranslation('Header');

  let close = (
    <div
      className="close"
      onClick={() => {
        props.onCloseArticle()
      }}
    ></div>
  )

  return (
    <div
      ref={props.setWrapperRef}
      id="main"
      style={props.timeout ? { display: 'flex' } : { display: 'none' }}
    >
      <article
        id="norskkurs"
        className={`${props.article === 'norskkurs' ? 'active' : ''} ${props.articleTimeout ? 'timeout' : ''
          }`}
        style={{ display: 'none' }}
      >
        <h2 className="major">{t("norskkurs")}</h2>
        <span className="image main">
          <img src={pic01} alt="" />
        </span>
        <div
          className='buttonBox'
        >
          <button
            className='articleButton'
            onClick={() => {
              props.onSelectCourse('A1')
            }}
          >
            {t('ButtonA1')}
          </button>
          <button
            className='articleButton'
            onClick={() => {
              props.onSelectCourse('A2')
            }}
          >
            {t('ButtonA2')}
          </button>
          <button
            className='articleButton'
            onClick={() => {
              props.onSelectCourse('B1')
            }}
          >
            {t('ButtonB1')}
          </button>
          <button
            className='articleButton'
            onClick={() => {
              props.onSelectCourse('B2')
            }}
          >
            {t('ButtonB2')}
          </button>
        </div>
        <div
          style={{ 'margin-top': '12px' }}
        >
          <p>
            {props.selectedCourse === 'A1' && <A1 />}
            {props.selectedCourse === 'A2' && <A2 />}
            {props.selectedCourse === 'B1' && <B1 />}
            {props.selectedCourse === 'B2' && <B2 />}
          </p>
        </div>
        {close}
      </article>
      <article
        id="elever"
        className={`${props.article === 'elever' ? 'active' : ''} ${props.articleTimeout ? 'timeout' : ''
          }`}
        style={{ display: 'none' }}
      >
        <h2 className="major">{t("elever")}</h2>
        <StudentCarousel />
        {close}
      </article>

      <article
        id="nyheter"
        className={`${props.article === 'laererne' ? 'active' : ''} ${props.articleTimeout ? 'timeout' : ''
          }`}
        style={{ display: 'none' }}
      >
        <Teacher />
        {close}
      </article>

      <article
        id="hvorfor"
        className={`${props.article === 'kontakt' ? 'active' : ''} ${props.articleTimeout ? 'timeout' : ''
          }`}
        style={{ display: 'none' }}
      >
        <h2 className="major">{t("kontakt")}</h2>
        <Contact />
        {close}
      </article>
    </div>
  )
}


Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
