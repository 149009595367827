import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from "react-i18next"

const Header = props => {
	const { t, i18n } = useTranslation('Header');
return (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">

    </div>
    <div className="content" style={{"display" : "flex"}}>
      <div className="inner">
        <h1>Kompetansesenter</h1>
        <p>
          {t('haslo')}
        </p>
      </div>
    </div>
    <nav style={{"display" : "flex"}}>
      <ul style={{"display" : "flex"}}>
        <li style={{"flex" : 1}}>
          <button
					style={{"flex" : 1}}
            onClick={() => {
              props.onOpenArticle('norskkurs')
            }}
          >
            {t('norskkurs')}
          </button>
        </li>
        <li style={{"flex" : 1}}>
          <button
					style={{"flex" : 1}}
            onClick={() => {
              props.onOpenArticle('elever')
            }}
          >
            {t('elever')}
          </button>
        </li>
        <li  style={{"flex" : 1}}>
          <button
					style={{"flex" : 1}}
            onClick={() => {
              props.onOpenArticle('laererne')
            }}
          >
            {t('laererne')}
          </button>
        </li>
        <li style={{"flex" : 1}}>
          <button
					style={{"flex" : 1}}
            onClick={() => {
              props.onOpenArticle('kontakt')
            }}
          >
            {t('kontakt')}
          </button>
        </li>
      </ul>
    </nav>
  </header>
)
}
Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
