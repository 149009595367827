import React from "react"


const Student = ({sName, sImage, sText}) => {

    return (
        <div className="studentBox">
            <h3 className="studentName">{sName}</h3>
            {sImage ? <img className="studentImg" src={sImage} /> : null}
            <p className="studentText">{sText}</p>
        </div>
    )
}

export default Student