import React, { useState } from "react"

export const CarouselItem = ({ children, width }) => {
    return (
        <div className="carousel-item" style={{ width: width }}>
            {children}
        </div>
    )
}

const Carousel = ({ children }) => {
    const [activeIndex, setActiveIndex] = useState(0)

    const updateIndex = (newIndex) => {
        if (newIndex < 0) {
            newIndex = 0
        } else if (newIndex >= React.Children.count(children)) {
            newIndex = React.Children.count(children) - 1
        }
        setActiveIndex(newIndex)
    }

    return (
        <div className="carousel">
            <div classeName="buttonBox" style={{ display: "flex",   justifyContent: "space-between" }}>
                <button style={{ float: "left" }} onClick={() => updateIndex(activeIndex - 1)}><i className="icon fa-arrow-left" /></button>
                <button style={{ float: "right" }} onClick={() => updateIndex(activeIndex + 1)}><i className="icon fa-arrow-right" /></button>
            </div>
            <div className="carouselInner" style={{ transform: `translateX(-${activeIndex * 100}%)` }}>
                {
                    React.Children.map(children, (child, index) => {
                        return React.cloneElement(child, { width: "100%" })
                    })
                }
            </div>
        </div>
    )
}

export default Carousel