import React, {useState} from "react"
import i18n from 'i18next'
import noFlag from "../images/no.png"
import enFlag from "../images/gb.png"
import plFlag from "../images/pl.png"

const LanguageSelector = () => {
  let lang = i18n.language + 'Flag'
	const [activeFlag, setActiveFlag] = useState(plFlag);

  return (
		<div style={{'width': '100%', 'position': 'fixed'}}>
    <div className='dropdown'>
			<button
			style={{'boxShadow': 'none'}}
			className="dropbtn">
				<img src={activeFlag} alt="norsk" />
			</button>
			<div className="dropdown-content" style={{left:0}}>
				<button type="button"
				onClick={() => {
					i18n.changeLanguage('no')
					setActiveFlag(noFlag)
				}}
				style={{'boxShadow': 'none'}}>
					<img src={noFlag} alt="norsk" />
				</button>
				<button type="button" onClick={() => {
					i18n.changeLanguage('en')
					setActiveFlag(enFlag)
				}}
				style={{'boxShadow': 'none'}}>
					<img src={enFlag} alt="english" />
				</button>
				<button type="button"
				onClick={() => {
					i18n.changeLanguage('pl')
					setActiveFlag(plFlag)
				}}
				style={{'boxShadow': 'none'}}>
					<img src={plFlag} alt="polski" />
				</button>
			</div>
		</div>
		</div>
  )
}
export default LanguageSelector
