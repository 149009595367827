import React from 'react'
import Carousel, {CarouselItem} from './Carousel'
import Student from './Student'
import { useTranslation } from "react-i18next"
import malgorzataImg from '../../images/students/malgorzata.jpg'
import agnieszkaipiotrImg from '../../images/students/agnieszkaipiotr.jpg'
import jedrzejImg from '../../images/students/jedrzej.jpg'
import maciejImg from '../../images/students/maciej.jpg'

const StudentCarousel = () => {
    const { t, i18n } = useTranslation('Students');

    return <Carousel>
            <CarouselItem><Student sName="Małgorzata" sImage={malgorzataImg} sText={t("malgorzata")} /></CarouselItem>
            <CarouselItem><Student sName="Agnieszka i Piotr" sImage={agnieszkaipiotrImg} sText={t("agnieszkaipiotr")} /></CarouselItem>
            <CarouselItem><Student sName="Jędrzej" sImage={jedrzejImg} sText={t("jedrzej")} /></CarouselItem>
            <CarouselItem><Student sName="Maciej" sImage={maciejImg} sText={t("maciej")} /></CarouselItem>
            <CarouselItem><Student sName="Grażyna" sText={t("grazyna")} /></CarouselItem>
	    <CarouselItem><Student sName="Katarzyna" sText={t("katarzyna")} /></CarouselItem>
	    <CarouselItem><Student sName="Anna K." sText={t("annak")} /></CarouselItem>
	    <CarouselItem><Student sName="Eryk" sText={t("eryk")} /></CarouselItem>
	    <CarouselItem><Student sName="Agata" sText={t("agata")} /></CarouselItem>
	    <CarouselItem><Student sName="Beata" sText={t("beata")} /></CarouselItem>
	    <CarouselItem><Student sName="Patrycja" sText={t("patrycja")} /></CarouselItem> 
	    <CarouselItem><Student sName="Anna" sText={t("anna")} /></CarouselItem>
	    <CarouselItem><Student sName="Łukasz" sText={t("lukasz")} /></CarouselItem>
	    <CarouselItem><Student sName="Natalia" sText={t("natalia")} /></CarouselItem>
	    <CarouselItem><Student sName="Francesca" sText={t("francesca")} /></CarouselItem> 
	    <CarouselItem><Student sName="Aleksandra" sText={t("aleksandra")} /></CarouselItem>	
	    <CarouselItem><Student sName="Edyta i Piotr" sText={t("piotriedyta")} /></CarouselItem>
	    <CarouselItem><Student sName="Maciej" sText={t("maciek")} /></CarouselItem>
	    <CarouselItem><Student sName="Natalia" sText={t("natalia")} /></CarouselItem>
  </Carousel>
}

export default StudentCarousel